import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
import JsonField from '@/shared/fields/json-field';
// import IntegerField from '@/shared/fields/integer-field';
// import EnumeratorField from '@/shared/fields/enumerator-field';
// import ImagesField from '@/shared/fields/images-field';

function label(name) {
  return i18n(`entities.city.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  // id: new IntegerField('id', label('id')),
  // country_id: new IntegerField('country_id', label('country_id'), {}),
  name: new JsonField('name', label('name'), {}),
  country_id: new StringField('country_id', label('country_id'), {}),
  city_ascii: new StringField('city_ascii', label('city_ascii'), {}),
  center: new JsonField('center', label('center'), {}),
  country: new JsonField('country', label('country'), {}),

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class CityModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
