//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import { CityModel } from '@/modules/city/city-model';
const { fields } = CityModel;

export default {
  name: 'app-city-view-page',
  props: ['id'],

  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      record: 'city/view/record',
      loading: 'city/view/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
  },  
  async mounted() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'city/view/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = CityModel.presenter(row, fieldName);
      return val ? val : 'ـــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = row[fieldName]
      const valueEn = val ? val['en'] : null
      const valueAr = val ? val['ar'] : null
      const value = this.currentLanguageCode == 'ar'
        ? valueAr ? valueAr : valueEn
        : valueEn ? valueEn : valueAr
      return value ? value : 'ـــ'
      // return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterGeoPoint(row, fieldName, key) {
      // const val = CityModel.presenter(row, fieldName);
      const val = row[fieldName]
      return val && val[key] ? val[key] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
  },
};
